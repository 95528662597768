import React from "react";
import { Avatar } from "../components/metadata/Avatar";

import {
  Container,
  Layout,
} from "../components/page";
import { SEO } from "../components/metadata/index";

const About = () => (
  <Layout>
    <SEO title="/about" />
    <Container>
      <div className="about md:about">
        <div class="about-svg">
          <Avatar width="260" height="260" />
        </div>

        <div className="about-text">
          I solve problems.
        </div>
      </div>
    </Container>
  </Layout>
)

export default About
